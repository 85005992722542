import { Link } from "react-router-dom";
const Row = ({ stake, setStakeDetails, toggleClaimModalHandler }) => {
  const openClaimModal = () => {
    setStakeDetails(stake);
    toggleClaimModalHandler();
  };

  return (
    <tr>
      <td className="p-md-2 w-85px align-middle">{stake.stake_id}</td>
      <td className="p-md-2 w-185px align-middle">
        <img
          src={`data:image/png;base64,${stake.image}`}
          alt="Stake"
          className="object-fit-cover h-70px d-block w-100"
          style={{ minWidth: "70px" }}
        />
      </td>
      <td className="p-md-2 align-middle">{stake.stake_tile}</td>

      <td className="p-md-2 align-middle">{stake.start_time}</td>
      <td className="p-md-2 align-middle">
        {stake.remaining_time}/{stake.contract_duration}
      </td>
      <td className="p-md-2 align-middle">
        {stake.claimed_count}/{stake.claim_count_limit}
      </td>
      <td className="p-md-2 align-middle">{stake.stake_value_usd} $</td>
      <td className="p-md-2 align-middle">{stake.withdrawn_amount_usd} $</td>
      <td className="p-md-2 align-middle">{stake.withdrawable_final_usd} $</td>
      <td className="p-md-2 text-center align-middle">
        <Link
          className="me-3"
          to={
            stake.tiles_coordination
              ? `/lands?lat=${stake.tiles_coordination[0].latitude}&lng=${stake.tiles_coordination[0].longitude}`
              : "/"
          }
        >
          Open in map
        </Link>{" "}
        <button
          className="btn btn-md btn-success mx-auto"
          onClick={openClaimModal}
          disabled={!stake.claim}
        >
          Claim
        </button>
      </td>
    </tr>
  );
};

export default Row;
