const LoadingSpinner = () => {
  return (
    <tr className="empty-row">
      <td colSpan="10" className="text-center p-3">
        <div className="text-center">
          <span className="spinner-border spinner-border-md"></span>
        </div>
      </td>
    </tr>
  );
};

export default LoadingSpinner;
