import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { useAuth } from "@/context/authProvider";
import logo from "@/assets/images/logo/logorec.png";
import Navigation from "./components/Navigation";

const Header: React.FC = () => {
  const [scroll, setScroll] = useState<boolean>(false);
  const { isAuthenticated } = useAuth();
  const [menuActive, setMenuActive] = useState<boolean>(false);

  useEffect(() => {
    const handleScroll = () => {
      setScroll(window.scrollY > 300);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleMenuActive = () => {
    setMenuActive(!menuActive);
  };

  return (
    <header
      id="header_main"
      className={`header is-fixed ${scroll ? "is-fixed" : ""}`}
    >
      <div className="container">
        <div id="site-header-inner">
          <div className="header__logo">
            <NavLink to="/">
              <img src={logo} alt="PCO" />
            </NavLink>
          </div>
          <Navigation
            isAuthenticated={isAuthenticated}
            menuActive={menuActive}
            handleMenuActive={handleMenuActive}
          />
          <div className="d-flex align-items-center">
            <div className="connect-btn">
              <ConnectButton chainStatus="none" />
            </div>
            <div
              className={`mobile-button ${menuActive ? "active" : ""}`}
              onClick={handleMenuActive}
            >
              <span></span>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
