import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { contractAbi } from "@/utils/contractConstants";
import LandingHeader from "@/components/Layout/HomeLayout/Header";
import { api } from "@/utils/axiosInstance.js";
import { useAuth } from "@/context/authProvider.js";
import { toast } from "react-toastify";
import {
  useAccount,
  useWriteContract,
  useWaitForTransactionReceipt,
} from "wagmi";
import Form from "./components/Form";

const SuccessMsg = (hashCode) => {
  const firstFive = hashCode.slice(0, 5); // Get the first 5 characters
  const lastFive = hashCode.slice(-5);
  return (
    <div>
      <p>Congratulations! You have successfully registered.</p>
      <a
        href={`https://polygonscan.com/tx/${hashCode}`}
        target="_blank"
        rel="norefreer noreferrer"
      >{`${firstFive} ... ${lastFive}`}</a>
    </div>
  );
};
const Signup = () => {
  const { checkUser } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const { address } = useAccount();
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    referral_id: "",
  });
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value.toLocaleLowerCase(),
    });
  };

  const { data, writeContract, isSuccess, isError, error, isPending } =
    useWriteContract();
  useEffect(() => {
    document.body.classList.add("dashboard-body");
    return () => {
      document.body.classList.remove("dashboard-body");
    };
  });

  useEffect(() => {
    if (isError) {
      setIsLoading(false);
      if (error?.cause?.code === 4001 || error?.cause?.cause?.code === 4001) {
        toast.error("You denied transaction signature.");
      } else if (error?.cause?.cause?.shortMessage) {
        toast.error(error?.cause?.cause?.shortMessage);
      }
    }
  }, [isError, error]);

  const handleUserRegister = async (email) => {
    const { username, referral_id } = formData;
    const referralId = referral_id.length ? referral_id : "0x";
    try {
      writeContract({
        address: process.env.REACT_APP_USER_REGISTERY_CONTRACT_ADDRESS,
        abi: contractAbi,
        functionName: "registerUser",
        args: [address, username, email, referralId],
      });
    } catch (error) {
      // handleDisconnection();
      toast.error("Error signing message or sending transaction:");
      console.error(
        "Error signing message or sending transaction:",
        error.message
      );
      setIsLoading(false);
    }
  };

  const transactionReceipt = useWaitForTransactionReceipt({
    hash: data,
  });

  useEffect(() => {
    if (isSuccess) {
      toast.success(SuccessMsg(data));
      setIsLoading(false);

      // checkUser(() => navigate("/dashboard/profile"));
    }
  }, [isSuccess]);
  useEffect(() => {
    if (transactionReceipt.isSuccess && transactionReceipt.data) {
      checkUser(() => navigate("/dashboard/profile"));
    }
  }, [transactionReceipt]);
  const checkIsUserDuplicated = () => {
    setIsLoading(true);
    api
      .post("/check_duplicate/", {
        username: formData.username,
        email: formData.email,
      })
      .then((res) => {
        if (res.data.status === "Success") {
          handleUserRegister(res.data.encoded_email);
        } else if (res.data.status === "Error") {
          toast.error(res.data.message);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error("Something went wrong!");
      });
  };
  useEffect(() => {
    if (isPending) setIsLoading(true);
  }, [isPending]);

  return (
    <div>
      <LandingHeader />
      <Form
        isLoading={isLoading}
        transactionReceipt={transactionReceipt}
        checkIsUserDuplicated={checkIsUserDuplicated}
        handleChange={handleChange}
        formData={formData}
      />
    </div>
  );
};

export default Signup;
