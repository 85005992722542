import { useState } from "react";
import { copyToClipboard } from "@/utils/copyToClipboard.js";
import Modal from "./Modal";
import Card from "./Card";

const CardContainer = ({ nftData }) => {
  const [openModal, setOpenModal] = useState(false);
  const [nftDetails, setNftDetails] = useState(null);
  const closeModalHandler = () => {
    setOpenModal(false);
  };
  const handleStacking = ({ token_id }) => {
    setNftDetails({ token_id });
    setOpenModal(true);
  };
  return (
    <div className="row g-3 g-xl-4 mb-5">
      <h3>How to Import an NFT into Your Wallet</h3>
      <div className="fs-5">
        To manually import an NFT into your wallet, open your wallet and go to
        the "NFT" tab.
        <br />
        Click on "Import NFTs" or "Add Token." <br /> Enter the token address:
        <div
          className="input-group flex-nowrap my-2"
          style={{ maxWidth: "max-content" }}
        >
          <input
            type="text"
            className="form-control"
            id="username"
            placeholder="username"
            defaultValue={process.env.REACT_APP_LAND_TO_NFT_CONTRACT_ADDRESS}
            readOnly
          />
          <span
            className="input-group-text"
            onClick={() =>
              copyToClipboard(
                process.env.REACT_APP_LAND_TO_NFT_CONTRACT_ADDRESS
              )
            }
          >
            <i className="bi me-1 bi-copy"></i>
          </span>
        </div>
        in the "Address" field and the Token/Land ID of your NFT in the "Token
        ID" field. <br /> Confirm by clicking "Add" or "Import."
      </div>
      {nftData.map(([id, token]) => (
        <div key={id} className="col-xl-5 col-xxl-4 col-lg-6 col-12">
          <Card
            tokenId={id}
            tile_count={token.tile_count}
            purchasepriceusd={token.purchasepriceusd}
            image={`data:image/png;base64,${token.picture}`}
            landtype={token.landtype}
            handleStacking={() => handleStacking({ token_id: id })}
            coordination={token.tile_data[0]}
            is_staked={token.is_staked}
          />
        </div>
      ))}
      {openModal && (
        <Modal closeModalHandler={closeModalHandler} nftDetails={nftDetails} />
      )}
    </div>
  );
};

export default CardContainer;
