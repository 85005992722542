import React from "react";
import { useAccount } from "wagmi";
import { useRefereesDataQuery } from "@/queries/referees";
import { HexAddress } from "@/types/address";
import LoadingSpinner from "../components/LoadingSpinner";
import NoData from "../components/NoData";
import Row from "./components/Row";

const Referees: React.FC = () => {
  const { address } = useAccount();
  const { data: refereesData, isLoading } = useRefereesDataQuery(
    address as HexAddress
  );

  return (
    <div
      id="tableHeadOptions"
      className="table-responsive mt-5 mb-5 text-center"
    >
      <table className="table table-hover text-nowrap table-bordered">
        <thead>
          <tr>
            <th scope="col" colSpan={2}>
              Username
            </th>
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <LoadingSpinner colSpan={5} />
          ) : refereesData && refereesData.length > 0 ? (
            refereesData.map((referee, index) => (
              <Row key={index} index={index} referee={referee} />
            ))
          ) : (
            <NoData colSpan={5} />
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Referees;
