import React from "react";

const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear();

  return (
    <div id="footer" className="app-footer">
      &copy; {currentYear} SeanTheme All Rights Reserved
    </div>
  );
};

export default Footer;
