import React, { useEffect } from "react";
import { useSignMessage, useAccount } from "wagmi";
import { useAuth } from "@/context/authProvider";
import BaseModal from "../BaseModal";

const SignModal: React.FC = () => {
  const { showSignModal, setShowSignModal, handleDisconnection, verifyUser } =
    useAuth();
  const closeModalHandler = () => {
    setShowSignModal(false);
    handleDisconnection();
  };

  const { address, isDisconnected } = useAccount();
  const { signMessage, data: signature, isSuccess, isError } = useSignMessage();

  useEffect(() => {
    if (isError) {
      closeModalHandler();
    }
  }, [isError]);

  useEffect(() => {
    if (isSuccess) {
      setShowSignModal(false);
      verifyUser(signature);
    }
  }, [isSuccess, signature]);

  useEffect(() => {
    if (isDisconnected) {
      closeModalHandler();
    }
  }, [isDisconnected]);

  return showSignModal ? (
    <BaseModal closeModalHandler={closeModalHandler} title="Sign Message">
      <div>
        <p>
          To proceed, please sign the message using your wallet. This will
          verify your identity and allow us to perform secure actions on your
          behalf.
        </p>
        <button
          className="btn btn-lg"
          onClick={() => signMessage({ message: address || "" })}
        >
          Sign
        </button>
      </div>
    </BaseModal>
  ) : null;
};

export default SignModal;
