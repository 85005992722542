import React, { useEffect } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import SidebarNav from "./SidebarNav";

const Sidebar: React.FC = () => {
  useEffect(() => {
    const body = document.querySelector("body");
    body?.classList.add("app-init");

    const handleSidebarMenuToggle = (menus: NodeListOf<HTMLAnchorElement>) => {
      menus.forEach((menu) => {
        menu.onclick = (e: MouseEvent) => {
          e.preventDefault();
          const target = menu.nextElementSibling as HTMLElement;

          menus.forEach((m) => {
            const otherTarget = m.nextElementSibling as HTMLElement;
            if (otherTarget !== target) {
              otherTarget.style.display = "none";
              otherTarget.closest(".menu-item")?.classList.remove("expand");
            }
          });

          const targetItemElm = target.closest(".menu-item") as HTMLElement;

          if (
            targetItemElm.classList.contains("expand") ||
            (targetItemElm.classList.contains("active") &&
              !target.style.display)
          ) {
            targetItemElm.classList.remove("expand");
            target.style.display = "none";
          } else {
            targetItemElm.classList.add("expand");
            target.style.display = "block";
          }
        };
      });
    };

    const menuBaseSelector = ".app-sidebar .menu > .menu-item.has-sub";
    const submenuBaseSelector = " > .menu-submenu > .menu-item.has-sub";

    // Menu
    const menuLinkSelector = menuBaseSelector + " > .menu-link";
    const menus = document.querySelectorAll(
      menuLinkSelector
    ) as NodeListOf<HTMLAnchorElement>;
    handleSidebarMenuToggle(menus);

    // Submenu lvl 1
    const submenuLvl1Selector = menuBaseSelector + submenuBaseSelector;
    const submenusLvl1 = document.querySelectorAll(
      submenuLvl1Selector + " > .menu-link"
    ) as NodeListOf<HTMLAnchorElement>;
    handleSidebarMenuToggle(submenusLvl1);

    // Submenu lvl 2
    const submenuLvl2Selector =
      menuBaseSelector + submenuBaseSelector + submenuBaseSelector;
    const submenusLvl2 = document.querySelectorAll(
      submenuLvl2Selector + " > .menu-link"
    ) as NodeListOf<HTMLAnchorElement>;
    handleSidebarMenuToggle(submenusLvl2);
  }, []);

  const toggleAppSidebarMobile = () => {
    const elm = document.querySelector(".app") as HTMLElement;
    elm.classList.toggle("app-sidebar-mobile-toggled");
  };

  return (
    <>
      <div id="sidebar" className="app-sidebar">
        <PerfectScrollbar
          className="app-sidebar-content"
          options={{ suppressScrollX: true }}
        >
          <SidebarNav />
          <div className="p-3 px-4 mt-auto">
            Powered by <span className="text-teal fw-bold">©PCOLand</span>
          </div>
        </PerfectScrollbar>
      </div>
      <button
        className="app-sidebar-mobile-backdrop"
        onClick={toggleAppSidebarMobile}
      ></button>
    </>
  );
};

export default Sidebar;
