import { useState, useEffect } from "react";
import Table from "./components/Table";
import { api } from "@/utils/axiosInstance.js";
import { useAccount } from "wagmi";
import { useLocation } from "react-router-dom";

const MyStakings = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [stakes, setStakes] = useState(null);
  const { address } = useAccount();
  const { pathname } = useLocation();
  const [refetch, setRefetch] = useState(false);
  const getUserStakings = async () => {
    setIsLoading(true);
    api
      .post("/dashboard_stakes/", { user_address: address })
      .then((response) => {
        setIsLoading(false);
        setStakes(response.data.stakes);
      });
  };
  useEffect(() => {
    getUserStakings();
  }, [pathname, refetch]);
  return (
    <Table isLoading={isLoading} stakes={stakes} setRefetch={setRefetch} />
  );
};

export default MyStakings;
