import React from "react";

const NoData = () => {
  return (
    <tr className="empty-row">
      <td colSpan="11" className="text-center p-3">
        <div className="text-gray-300 mb-2">
          <i className="fas fa-fw me-2 fa-database fa-3x"></i>
        </div>
        No Data
      </td>
    </tr>
  );
};

export default NoData;
