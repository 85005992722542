import { useEffect, useState } from "react";
import { useWriteContract, useWaitForTransactionReceipt } from "wagmi";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { stakeNFTAbi, landNFTAbi } from "@/utils/contractConstants";
import StakeTypeSelector from "./StakeTypeSelector";
import Stepper from "@/components/Common/Stepper";
import BaseModal from "@/components/Common/BaseModal";

const Modal = ({ nftDetails, closeModalHandler }) => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [stakeTypes, setStakeTypes] = useState([]);
  const [showStepper, setShowStepper] = useState(false);
  const [selectedStakeType, setSelectedStakeType] = useState(""); // Added state for selected land type
  const approve = useWriteContract();
  const stake = useWriteContract();
  const transactionReceipt = useWaitForTransactionReceipt({
    hash: approve.data,
    confirmations: 3,
  });
  const approveHandler = async () => {
    await approve.writeContractAsync({
      address: process.env.REACT_APP_LAND_TO_NFT_CONTRACT_ADDRESS,
      abi: landNFTAbi,
      functionName: "approve",
      args: [
        process.env.REACT_APP_STAKE_NFT_CONTRACT_ADDRESS,
        Number(nftDetails.token_id),
      ],
    });
  };
  const stakeHandler = async () => {
    await stake.writeContractAsync({
      address: process.env.REACT_APP_STAKE_NFT_CONTRACT_ADDRESS,
      abi: stakeNFTAbi,
      functionName: "stake",
      args: [Number(nftDetails.token_id), Number(selectedStakeType)],
    });
  };
  useEffect(() => {
    if (approve.isError) {
      if (approve.error?.cause?.code === 4001) {
        toast.error("You rejected the spending cap request for your PMB.");
        // closeModalHandler();
      }
    }
  }, [approve.isError]);
  useEffect(() => {
    if (transactionReceipt.isSuccess) {
      stakeHandler();
    }
  }, [transactionReceipt.isSuccess]);

  useEffect(() => {
    if (transactionReceipt.error) {
      transactionReceipt.refetch();
    }
  }, [transactionReceipt.error]);

  const convertButtonHandler = async () => {
    try {
      setShowStepper(true);
      approveHandler();
    } catch (error) {
      console.error("Error capturing image:", error);
    }
  };
  useEffect(() => {
    if (stake.isSuccess) {
      toast.success("Your NFT Has Been Successfully Staked!");
      setTimeout(() => {
        closeModalHandler();
        navigate("/dashboard/my-stakings");
      }, 1000);
    }
  }, [stake.isSuccess]);
  const isBtnDisabled =
    transactionReceipt.isFetching ||
    approve.isPending ||
    isLoading ||
    stake.isPending ||
    stakeTypes.length === 0;
  const stepperList = [
    {
      id: 1,
      title: approve.isPending
        ? "Waiting for Wallet Approval"
        : approve.isError
        ? "Failed to Approve"
        : transactionReceipt.isFetching
        ? "Waiting for Transaction Confirmation"
        : transactionReceipt.isSuccess
        ? "Approval Completed"
        : "Approve",
      status:
        approve.isPending || transactionReceipt.isFetching
          ? "in-progress"
          : transactionReceipt.isSuccess
          ? "active"
          : approve.isError || transactionReceipt.isError
          ? "failed"
          : "in-active",
      retryHandler: approve.isError
        ? approveHandler
        : transactionReceipt.isError
        ? transactionReceipt.refetch
        : null,
    },
    {
      id: 2,
      title: stake.isPending
        ? "Waiting for Wallet Confirmation To Stake"
        : stake.isError
        ? "Failed to Stake NFT"
        : stake.isSuccess
        ? "Staked NFT"
        : "Stake NFT",
      status: stake.isPending
        ? "in-progress"
        : stake.isSuccess
        ? "active"
        : stake.isError
        ? "failed"
        : "inactive",
      retryHandler: stake.isError ? stakeHandler : null,
    },
  ];
  return (
    <BaseModal
      title="Stake Your NFT"
      closeModalHandler={closeModalHandler}
      className="nft-modal"
    >
      <StakeTypeSelector
        nftDetails={nftDetails}
        selectedStakeType={selectedStakeType}
        setSelectedStakeType={setSelectedStakeType}
        setStakeTypes={setStakeTypes}
        stakeTypes={stakeTypes}
        setIsLoading={setIsLoading}
        isBtnDisabled={isBtnDisabled}
      />
      {showStepper ? (
        <Stepper list={stepperList} />
      ) : (
        <button
          className="btn btn-lg w-94px mt-4"
          onClick={convertButtonHandler}
          disabled={isBtnDisabled || selectedStakeType === ""}
        >
          Confirm
        </button>
      )}
    </BaseModal>
  );
};

export default Modal;
