import React from "react";
import { Outlet } from "react-router-dom";

interface ContentProps {
  className?: string;
}

const Content: React.FC<ContentProps> = ({ className }) => {
  return (
    <div className={`app-content ${className ? className : ""}`}>
      <Outlet />
    </div>
  );
};

export default Content;
