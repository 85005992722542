import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import logo from "@/assets/images/logo/logorec.png";
import img1 from "@/assets/images/background/bg-ft.png";
import img2 from "@/assets/images/background/bg-ft2.png";
import { SocialLink } from "./components/SocialLink";
import { FooterMenu } from "./components/FooterMenu";
import { SubscriptionForm } from "./components/SubscriptionForm";

const Footer: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false);
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 500) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);
    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  return (
    <footer id="contact-us" className="landing-footer">
      <div className="footer-main">
        <img src={img1} alt="" className="bg1" />
        <img src={img2} alt="" className="bg2" />

        <div className="container z-3 position-relative">
          <div className="mb-5 text-center">
            <h5 className="heading">
              don’t miss out, join now for early access
            </h5>
            <p>
              Discover PCOLand, the ultimate platform for trading metaverse
              properties.
            </p>

            <SubscriptionForm recaptchaRef={recaptchaRef} />
          </div>
          <div className="brand-logo">
            <img src={logo} alt="logo" />
            <span>PCOLAND</span>
          </div>
          <FooterMenu />
          <SocialLink />
        </div>
      </div>
      <div className="footer-bottom">
        <div className="container">
          <div className="wrap-fx">
            <div className="Copyright">
              <p>PCO 2024 - ALL rights reserved</p>
            </div>
            <ul className="list">
              <li>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/terms-conditions">Terms & Conditions</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {isVisible && (
        <Link onClick={scrollToTop} to="#" id="scroll-top">
          <div>
            {" "}
            <i className="fas fa-lg fa-fw fa-arrow-up" />
          </div>
        </Link>
      )}
    </footer>
  );
};

export default Footer;
