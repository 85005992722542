import React from "react";

interface Step {
  id: string | number;
  title: string;
  status: "active" | "inactive" | "failed" | "completed"; // or whatever status values you need
  retryHandler?: () => void;
}

interface StepperProps {
  list: Step[];
}

const Stepper: React.FC<StepperProps> = ({ list }) => {
  return (
    <div className="stepper-container">
      <div className="line">
        {list.map((item, index) => (
          <div className={`step ${item.status}`} key={item.id}>
            <div id={`step-${index + 1}`} className="circle-container">
              {item.status === "active" ? (
                <svg
                  width="37px"
                  height="37px"
                  viewBox="0 0 37 37"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  className="tick-box"
                >
                  <g
                    id="check-group"
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                  >
                    <circle
                      id="filled-circle"
                      fill="#15F3A8"
                      cx="18.5"
                      cy="18.5"
                      r="15.5"
                    ></circle>
                    <circle
                      id="white-circle"
                      fill="#FFFFFF"
                      cx="18.5"
                      cy="18.5"
                      r="16.5"
                    ></circle>
                    <circle
                      id="outline"
                      stroke="#15F3A8"
                      strokeWidth="2"
                      cx="18.5"
                      cy="18.5"
                      r="15.5"
                    ></circle>
                    <polyline
                      id="check"
                      stroke="#FFFFFF"
                      strokeWidth="2"
                      points="12 19 17 24 26 15"
                    ></polyline>
                  </g>
                </svg>
              ) : item.status === "failed" ? (
                <div className="cross-icon cross-delete animateDeleteIcon">
                  <span className="cross-x-mark animateXMark">
                    <span className="cross-delete-line cross-delete-left"></span>
                    <span className="cross-delete-line cross-delete-right"></span>
                  </span>
                </div>
              ) : (
                <>
                  <div className={`circle ${item.status}`}></div>
                  <div className={`pulse ${item.status}`}></div>
                </>
              )}
            </div>
            <div className="text-container">
              <div>{item.title}</div>
              {item.retryHandler && (
                <button
                  onClick={item.retryHandler}
                  className="text-danger mt-3 bg-danger-transparent rounded-1"
                >
                  Retry
                </button>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Stepper;
