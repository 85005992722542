import React from "react";
import { copyToClipboard } from "@/utils/copyToClipboard.js";

const Contracts = () => {
  const contractsList = [
    {
      title: "1. UserRegistry Contract Address (Proxy) :",
      value: process.env.REACT_APP_USER_REGISTERY_CONTRACT_ADDRESS,
    },
    {
      title: "2. LandCoreV2 Contract Address (Proxy) :",
      value: process.env.REACT_APP_LAND_CONTRACT_ADDRESS,
    },
    {
      title: "3. LandNFT Contract Address (Proxy) :",
      value: process.env.REACT_APP_LAND_TO_NFT_CONTRACT_ADDRESS,
    },
    {
      title: "4. LandNFTStake Contract Address (Proxy) :",
      value: process.env.REACT_APP_STAKE_NFT_CONTRACT_ADDRESS,
    },
    {
      title: "5. PMETokenV3 Contract Address (Proxy) :",
      value: process.env.REACT_APP_PME_CONTRACT_ADDRESS,
    },
    {
      title: "6. PMGToken Contract Address (Proxy) :",
      value: process.env.REACT_APP_PMG_CONTRACT_ADDRESS,
    },

    {
      title: "7. PMBToken (PcolandMetaEarthBridge) :",
      value: process.env.REACT_APP_PMB_CONTRACT_ADDRESS,
    },
  ];
  return (
    <div className="post-details contracts">
      <section className="tf-section detail">
        <div className="container">
          <div className="row">
            <article
              className="article"
              data-aos="fade-in"
              data-aos-duration="800"
            >
              <div className="post p-b23">
                <h4 className="title">Smart Contract Addresses</h4>
              </div>
              {contractsList.map((contract, index) => (
                <div className="post-details" key={index}>
                  <h5>{contract.title}</h5>
                  <div
                    className="input-group flex-nowrap my-2"
                    onClick={() => copyToClipboard(contract.value)}
                  >
                    <input
                      type="text"
                      className="form-control"
                      id="username"
                      placeholder="username"
                      defaultValue={contract.value}
                      readOnly
                    />
                    <span className="input-group-text">
                      <i className="bi me-1 bi-copy"></i>
                    </span>
                  </div>
                </div>
              ))}
            </article>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Contracts;
