import React from "react";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import BaseModal from "../BaseModal";

interface ConnectWalletModalProps {
  closeModalHandler: () => void;
}

const ConnectWalletModal: React.FC<ConnectWalletModalProps> = ({
  closeModalHandler,
}) => {
  return (
    <BaseModal
      closeModalHandler={closeModalHandler}
      title="Connect Your Wallet"
    >
      <div>
        <p>To continue and purchase land, please connect your wallet.</p>
        <div className="connect-btn d-flex justify-content-center">
          <ConnectButton chainStatus="none" />
        </div>
      </div>
    </BaseModal>
  );
};

export default ConnectWalletModal;
