import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { api } from "@/utils/axiosInstance";
import { toast } from "react-toastify";

interface SubscriptionFormProps {
  recaptchaRef: React.RefObject<ReCAPTCHA>;
}

export const SubscriptionForm: React.FC<SubscriptionFormProps> = ({ recaptchaRef }) => {
  const [email, setEmail] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<{ email?: string }>({});

  const validate = () => {
    let errors: { email?: string } = {};
    if (!email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = "Email address is invalid";
    }
    return errors;
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const validationErrors = validate();
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      setIsLoading(true);
      try {
        const token = await recaptchaRef.current?.executeAsync();
        recaptchaRef.current?.reset(); // Reset reCAPTCHA for next use
        if (!token) throw new Error("Failed to obtain reCAPTCHA token. Please try again.");
        
        const response = await api.post("/subscription_email/", { email });
        if (response.data.status === "Success") {
          toast.success("Subscription successful! Thank you for subscribing.");
          setEmail("");
        } else {
          toast.error("Subscription failed. Please try again.");
        }
      } catch (error) {
        console.error("Error during submission:", error);
        toast.error((error as any).response?.data?.message || "An error occurred. Please try again.");
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <form action="#" id="subscribe-form" onSubmit={handleSubmit}>
      <input
        type="email"
        name="email"
        value={email}
        onChange={(e) => setEmail(e.target.value.toLowerCase())}
        placeholder="Enter your email address"
        required
        id="subscribe-email"
        className={`${errors.email ? "border-danger" : ""}`}
      />
      {errors.email && (
        <div className="text-danger position-absolute" style={{ bottom: "-32px" }}>
          {errors.email}
        </div>
      )}
      <button className="tf-button-st2 btn-effect" type="submit" id="subscribe-button" disabled={isLoading}>
        {isLoading ? <span className="spinner-border"></span> : <span className="effect">Subscribe</span>}
      </button>
      <ReCAPTCHA ref={recaptchaRef} size="invisible" sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY!} />
    </form>
  );
};
