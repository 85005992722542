import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import Cookies from "js-cookie";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { useAccount, useDisconnect } from "wagmi";
import Loading from "@/components/Common/Loading";
import { api } from "../utils/axiosInstance.js";
import AnnouncementModal from "@/components/Common/AnnouncementModal";

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isFetchingUserData, setIsFetchingUserData] = useState(false);
  const [showSignModal, setShowSignModal] = useState(false);
  const [isSigned, setIsSigned] = useState(false);
  const [isSignupComplete, setIsSignupComplete] = useState(false);
  const [showAnnouncement, setShowAnnouncement] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { disconnect } = useDisconnect();
  const AnnouncementCookie = Cookies.get("showAnnouncement");
  const { address, isConnected, status } = useAccount();
  const hideAnnouncementModal = () => {
    setShowAnnouncement(false);
  };
  const showAnnouncementModal = () => {
    setShowAnnouncement(true);
    Cookies.set("showAnnouncement", true);
  };
  const dashboardAPI = () => {
    setIsLoading(true);
    api
      .post("/dashboard/", {
        user_address: address,
      })
      .then((res) => {
        setUser(res.data.data);
        setIsLoading(false);
      })
      .finally(() => setIsLoading(false));
  };
  const verifyUser = async (signature) => {
    setIsLoading(true);
    await api
      .post("/verify/", {
        user_address: address,
        signature,
      })
      .then((response) => {
        if (response.data.status === "Error") {
          handleDisconnection();
          setIsLoading(false);
          toast.error(response.data.message);
        } else if (response.data.status === "Success") {
          api
            .post("/check_user/", {
              user_address: address,
            })
            .then((response) => {
              Cookies.set("walletAddress", address);
              if (response.data.data) {
                setIsAuthenticated(true);
                navigate("/dashboard/profile");
                showAnnouncementModal();
                dashboardAPI();
                setIsSignupComplete(true);
                toast.success("You have logged in successfully.");
              } else {
                navigate("/signup");
                setIsSigned(true);
                toast.info("Please Complete the signup form.");
              }
              setIsLoading(false);
            })
            .catch((error) => {
              console.error("POST Error:", error);
              setIsLoading(false);
              toast.error("Something went wrong in checking user. ");
            });
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data?.message);
          console.log(error.response.status);
          console.log(error.response.headers);
        } // handleDisconnection();
        setIsLoading(false);
        toast.error(
          `"Something went wrong. verify" ${error.response.status} ${error.response.data?.message}`
        );
      });
  };
  const handleDisconnection = () => {
    disconnect();
    setIsSigned(false);
    setIsFetchingUserData(false);
    setIsAuthenticated(false);
    setIsLoading(false);
    setUser(null);
    Cookies.remove("walletAddress");
  };
  // useEffect(() => {
  //   if (isSignedSuccess) {
  //     // verifyUser();
  //   }
  // }, [isSignedSuccess]);

  useEffect(() => {
    if (!AnnouncementCookie && !showAnnouncement && user) {
      showAnnouncementModal();
    }
  }, [showAnnouncement, user]);
  useEffect(() => {
    const handleNavigation = () => {
      if (location.pathname !== "/signup" && !isSignupComplete && !user) {
        handleDisconnection();
      }
    };

    handleNavigation();
  }, [location.pathname, isSignupComplete, user]);

  // const storedAddress = Cookies.get("walletAddress");

  const checkUser = useCallback(
    (successCallback) => {
      setIsFetchingUserData(true);
      api
        .post("/check_user/", {
          user_address: address,
        })
        .then((response) => {
          Cookies.set("walletAddress", address);
          toast.info(response.data.message);
          if (response.data.data) {
            setIsAuthenticated(true);
            dashboardAPI();
            setIsSignupComplete(true);
            if (successCallback) {
              successCallback();
            }
          } else {
            // handleDisconnection();
            // navigate("/signup");
          }
        })
        .catch((error) => {
          console.error("POST Error:", error);
          setIsFetchingUserData(false);
        })
        .finally(() => setIsFetchingUserData(false));
    },
    [address]
  );
  useEffect(() => {
    if (!isConnected) {
      handleDisconnection();
    }
  }, [isConnected]);

  useEffect(() => {
    if (
      status !== "reconnecting" &&
      !isAuthenticated &&
      isConnected &&
      !isFetchingUserData &&
      !isSigned
    ) {
      setShowSignModal(true);
    }
  }, [
    status,
    address,
    isAuthenticated,
    isConnected,
    isFetchingUserData,
    isSigned,
  ]);

  useEffect(() => {
    if (address && !isAuthenticated) {
      checkUser();
    } else {
      // navigate("/");
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        // connectWallet,
        verifyUser,
        isLoading,
        checkUser,
        user,
        isAuthenticated,
        isConnected,
        address,
        isFetchingUserData,
        handleDisconnection,
        setIsSignupComplete,
        showSignModal,
        setShowSignModal,
      }}
    >
      {status === "reconnecting" || isLoading ? (
        <Loading />
      ) : (
        <>
          {children}
          {showAnnouncement && (
            <AnnouncementModal closeModalHandler={hideAnnouncementModal}>
              <div>
                <p>
                  The <strong>3-year</strong> contract will remain valid until{" "}
                  <span className="fw-600">September 20th</span>. <br />
                  To qualify, you need to have a minimum of 500 tiles by that
                  date. Afterward, the requirement will increase to 1000 tiles.
                  PME tokens will be accepted moving forward.
                </p>

                <p className="mb-1">
                  • <strong className="fw-700">Please note:</strong> <br />
                  <span className="fw-600">PMB</span> tokens will only be
                  accepted until <span className="fw-600">September 20th</span>.
                </p>
              </div>

              <button
                className="btn btn-lg w-94px mt-3"
                onClick={hideAnnouncementModal}
              >
                Ok
              </button>
            </AnnouncementModal>
          )}
        </>
      )}
    </AuthContext.Provider>
  );
};
