import { useEffect } from "react";
import { toast } from "react-toastify";
import { stakeNFTAbi } from "@/utils/contractConstants";
import { useWriteContract, useAccount } from "wagmi";

const ClaimModal = ({ closeModalHandler, stakeDetails, setRefetch }) => {
  const { address } = useAccount();
  const claim = useWriteContract();

  const claimHandler = async () => {
    await claim.writeContractAsync({
      address: process.env.REACT_APP_STAKE_NFT_CONTRACT_ADDRESS,
      abi: stakeNFTAbi,
      functionName: "claim",
      args: [stakeDetails.stake_id],
    });
  };
  useEffect(() => {
    if (claim.error) {
      toast.error(claim.error?.cause?.reason || "Claim failed.");
    }
  }, [claim.error]);
  useEffect(() => {
    if (claim.isSuccess) {
      toast.success("You Claimed Successfully");
      setRefetch(true);
      closeModalHandler();
    }
  }, [claim.isSuccess]);

  return (
    <div className="dark-modal nft-modal">
      <div className="modal show" id="modalSm" aria-modal="true" role="dialog">
        <div className="modal-dialog h-100 d-flex align-items-center">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Claim Your Stake</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                onClick={() => closeModalHandler()}
              ></button>
            </div>
            <div className="modal-body">
              <div>
                <p>
                  <strong>Wallet Address:</strong> {address.slice(0, 7)}...
                  {address.slice(-7)}
                </p>
                <p>
                  <strong> Title:</strong> {stakeDetails.stake_tile}
                </p>
                <p>
                  <strong>Fee:</strong> ${stakeDetails.withdrawable_fee_usd}
                </p>
                <p>
                  <strong>Stake Value:</strong> ${stakeDetails.stake_value_usd}
                </p>
                <p>
                  <strong>PMG Profit≃</strong> {stakeDetails.withdrawable_final_pmg}
                </p>{" "}
                <p>
                  <strong>PME Profit≃</strong> {stakeDetails.withdrawable_final_pme}
                </p>
                <p>
                  <strong>Claims:</strong> {stakeDetails.claimed_count}/
                  {stakeDetails.claim_count_limit} used
                </p>
              </div>
              <button
                className="btn btn-lg w-94px mt-4"
                disabled={claim.isPending || !stakeDetails.claim}
                onClick={claimHandler}
              >
                {claim.isPending ? (
                  <div className="d-flex align-items-center gap-2">
                    Claiming{" "}
                    <span className="spinner-border spinner-border-sm"></span>
                  </div>
                ) : (
                  "Claim"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show"></div>
    </div>
  );
};

export default ClaimModal;
