import { useAccount } from "wagmi";
import EmptyPage from "../components/EmptyPage";
import CardContainer from "./components/CardContainer";
import { useNFTDataQuery } from "@/queries/nft";

const MyNFTs = () => {
  const { address } = useAccount();
  const { data: nftList, isLoading } = useNFTDataQuery(address);
  const nftData = nftList ? Object.entries(nftList.user_tokens) : [];

  return (
    <div className="container-xxl p-3 p-lg-5">
      {isLoading ? (
        <div className="text-center">
          <span className="spinner-border spinner-border-md"></span>
        </div>
      ) : nftData.length > 0 ? (
        <CardContainer nftData={nftData} />
      ) : (
        <EmptyPage title="You have no NFT" />
      )}
    </div>
  );
};

export default MyNFTs;
