import { Link } from "react-router-dom";

const Card = ({
  tile_count,
  purchasepriceusd,
  image,
  landtype,
  tokenId,
  handleStacking,
  coordination,
  is_staked,
}) => {
  const landTypeColors = {
    Residential: "#de64f7",
    Educational: "#33FF57",
    Entertainment: "#3357FF",
    Town: "#F0F0F0",
  };
  const color = landTypeColors[landtype] || "#000000";

  return (
    <div className="card d-flex flex-column mb-5 mb-lg-0">
      <div className="card-body ">
        <img
          src={image}
          alt="NFT"
          className="object-fit-cover h-200px w-100 d-block"
        />
      </div>
      <div className="flex-1 px-3 pb-3">
        <div className="d-flex justify-content-between fw-bold fs-5 mt-3">
          <div className="d-flex gap-1 align-items-center">
            <div>Token/Land ID:</div>
          </div>
          <div>{tokenId} </div>
        </div>
        <div className="d-flex justify-content-between fw-bold fs-5 mt-3">
          <div className="d-flex gap-1 align-items-center">
            <i className="bi bi-currency-dollar"></i>
            <div>Value:</div>
          </div>
          <div>
            <i className="bi bi-currency-dollar"></i>
            {purchasepriceusd}{" "}
          </div>
        </div>
        <div className="d-flex justify-content-between fw-bold fs-5 mt-3">
          <div className="d-flex gap-1 align-items-center">
            <i className="fa-solid fa-earth-asia"></i>
            <div>Tiles:</div>
          </div>
          <div>{tile_count}</div>
        </div>
        <div className="d-flex justify-content-between fw-bold fs-5 mt-3">
          <div className="d-flex gap-1 align-items-center">
            <i className="fa-solid fa-mountain"></i> <div>NFT TYPE:</div>
          </div>
          <div style={{ color: color }}>{landtype}</div>
        </div>
        <div className="mt-3 mb-2 d-flex justify-content-between">
          <button
            onClick={handleStacking}
            disabled={is_staked}
            className={` me-3 bg-opacity-15 px-2 py-1 rounded medium fw-bold ${
              is_staked
                ? "bg-gray disabled text-secondary bg-secondary"
                : "bg-theme text-theme"
            }`}
          >
            Stake
          </button>
          {is_staked ? (
            <button
              className="bg-gray disabled text-secondary bg-secondary px-2 py-1 rounded
            medium fw-bold text-decoration-none bg-opacity-15"
            >
              Sell
            </button>
          ) : (
            <a
              disabled={is_staked}
              target="_blank"
              rel="noopener noreferrer"
              href={`https://opensea.io/assets/matic/${process.env.REACT_APP_LAND_TO_NFT_CONTRACT_ADDRESS}/${tokenId}`}
              className="bg-danger bg-opacity-25 text-danger px-2 py-1 rounded
            medium fw-bold text-decoration-none"
            >
              Sell
            </a>
          )}
        </div>
        <div className="w-100 d-flex justify-content-center pt-2">
          <Link
            to={
              coordination
                ? `/lands?lat=${coordination.latitude}&lng=${coordination.longitude}`
                : "/"
            }
            className="btn btn-outline-success"
          >
            Open in map
          </Link>
        </div>
      </div>

      <div className="card-arrow">
        <div className="card-arrow-top-left"></div>
        <div className="card-arrow-top-right"></div>
        <div className="card-arrow-bottom-left"></div>
        <div className="card-arrow-bottom-right"></div>
      </div>
    </div>
  );
};

export default Card;
