import React, { ReactNode } from "react";

interface BaseModalProps {
  closeModalHandler?: () => void;
  children: ReactNode;
  title?: string;
  className?: string;
}

const BaseModal: React.FC<BaseModalProps> = ({
  closeModalHandler,
  children,
  title,
  className,
}) => {
  return (
    <div className={`dark-modal ${className ?? ""}`}>
      <div
        className="modal show"
        id="baseModal"
        aria-modal="true"
        role="dialog"
      >
        <div className="modal-dialog h-100 d-flex align-items-center">
          <div className="modal-content">
            {title && (
              <div className="modal-header">
                <h5 className="modal-title">{title}</h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  onClick={closeModalHandler}
                ></button>
              </div>
            )}
            <div className="modal-body">
              <div>{children}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show"></div>
    </div>
  );
};

export default BaseModal;
