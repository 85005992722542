const ModalContainer = ({ closeModalHandler, children }) => {
  return (
    <div className="dark-modal nft-modal">
      <div className="modal show" id="modalSm" aria-modal="true" role="dialog">
        <div className="modal-dialog h-100 d-flex align-items-center">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Convert Your Land To NFT</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                onClick={() => closeModalHandler()}
              ></button>
            </div>
            <div className="modal-body">
              <div>{children}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show"></div>
    </div>
  );
};

export default ModalContainer;
