import React from "react";

interface RowProps {
  index: number;
  referee: string;
}

const Row: React.FC<RowProps> = ({ index, referee }) => {
  return (
    <tr>
      <td className="p-md-3 align-middle" colSpan={1}>
        {index + 1}
      </td>
      <td
        className="p-md-3 text-left"
        style={{ width: "94%", textAlign: "left" }}
      >
        <span className="me-5"> {referee} </span>

        <span className="ml-4 badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
          <i className="fa fa-circle fs-9px fa-fw me-5px"></i> Active
        </span>
      </td>
    </tr>
  );
};

export default Row;
