import { useState, useEffect, useContext } from "react";
import {
  useWriteContract,
  useWaitForTransactionReceipt,
  useBalance,
  useAccount,
  useReadContract,
} from "wagmi";
import { parseEther } from "ethers";
import {
  landAbi,
  approveAbi,
  pmeApproveAbi,
  maticTokenAddress,
} from "@/utils/contractConstants";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { api } from "@/utils/axiosInstance.js";
import { convertTilesToString } from "@/utils/landHelpers.js";
import { MapContext } from "../context.jsx";
import Modal from "./components/Modal.jsx";

const BuyLandModal = ({ closeModalHandler, contractData }) => {
  const {
    tileNumber,
    emptySelectedCells,
    selectedCells,
    setIsPurchased,
    titleInfo,
  } = useContext(MapContext);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isFetchingLand, setIsFetchingLand] = useState(false);
  const [fetchLandData, setFetchLandData] = useState();
  const [fetchLandError, setFetchLandError] = useState(false);
  const [buttonText, setButtonText] = useState("Confirm");
  const [selectTokenType, setSelectTokenType] = useState("");

  const { address } = useAccount();
  const queryClient = useQueryClient();
  const [showStepper, setShowStepper] = useState(false);
  const approve = useWriteContract();
  const purchaseLand = useWriteContract();
  const transactionReceipt = useWaitForTransactionReceipt({
    hash: approve.data,
    confirmations: 3,
  });

  const purchasePriceTokenInWei = parseEther(
    contractData.purchase_price_token.toString()
  );

  const userBalance = useBalance({ address });
  const pmbBalance = useBalance({
    address,
    token: process.env.REACT_APP_PMB_CONTRACT_ADDRESS,
  });
  const pmeBalance = useBalance({
    address,
    token: process.env.REACT_APP_PME_CONTRACT_ADDRESS,
  });
  const maticBalance = useBalance({ address, token: maticTokenAddress });

  const allowance = useReadContract({
    address: process.env.REACT_APP_PMB_CONTRACT_ADDRESS,
    abi: approveAbi,
    functionName: "allowance",
    args: [address, process.env.REACT_APP_LAND_CONTRACT_ADDRESS],
    watch: true,
  });

  // const isAllowed = allowance?.data >= purchasePriceTokenInWei;

  useEffect(() => {
    allowance.refetch();
    queryClient.invalidateQueries({ queryKey: userBalance.queryKey });
  }, []);

  useEffect(() => {
    if (purchaseLand.isSuccess) {
      const redirectHandler = async () => {
        await resetLockHandler();
        toast.success("Land Purchase Done! Processing Now.");

        const timeoutId = setTimeout(() => {
          closeModalHandler();
          setIsPurchased(true);
          navigate("/dashboard/purchased-lands");
        }, 15000);

        // Cleanup function to clear timeout if the component unmounts
        return () => clearTimeout(timeoutId);
      };

      redirectHandler();
    }
  }, [purchaseLand.isSuccess]);

  useEffect(() => {
    if (approve.isError) {
      if (approve.error?.cause?.code === 4001) {
        toast.error("You rejected the spending cap request for your PMB/PME.");
        // closeModalHandler();
      }
    }
  }, [approve.isError]);

  useEffect(() => {
    if (purchaseLand.error) {
      cancelBuyLand();
      toast.error(
        purchaseLand.error?.cause?.shortMessage || "Purchase failed."
      );
      // closeModalHandler();
      setIsLoading(false);
    }
  }, [purchaseLand.error]);

  useEffect(() => {
    if (transactionReceipt.isSuccess && !purchaseLand.isSuccess) {
      fetchBuyLandData();
    }
  }, [transactionReceipt.isSuccess]);

  useEffect(() => {
    if (transactionReceipt.isSuccess) {
      queryClient.invalidateQueries({ queryKey: allowance.queryKey });
    }
  }, [transactionReceipt.isSuccess]);

  useEffect(() => {
    if (transactionReceipt.error) {
      transactionReceipt.refetch();
    }
  }, [transactionReceipt.error]);

  const fetchBuyLandData = async () => {
    setIsFetchingLand(true);
    try {
      const res = await api.post("/buy_land/", {
        tile_count: tileNumber,
        tile_data: convertTilesToString(selectedCells),
        token_type: selectTokenType,
        is_forsale: false,
        user_address: address,
      });

      if (res.data.status === "Error") {
        toast.error(res.data.message);

        setFetchLandError(true);
      } else if (res.data.duplicated) {
        toast.error("This land has already been bought.");
      } else if (res.data.reserved) {
        toast.error("This land has already been reserved.");
      } else {
        setFetchLandData(res.data.data);
        purchaseLandHandler(res.data.data);
      }
    } catch (error) {
      toast.error(error.response?.data.message);
      setFetchLandError(true);
      if (error.response.status === 423) {
        emptySelectedCells();
        closeModalHandler();
      }
    } finally {
      setIsFetchingLand(false);
    }
  };

  const cancelBuyLand = async () => {
    try {
      await api.delete("/cancel_purchase/", {
        data: {
          tile_data: convertTilesToString(selectedCells),
          user_address: address,
        },
      });
    } catch (error) {
      console.error("Error canceling land purchase:", error);
    }
  };

  const resetLockHandler = () => {
    api.post("/reset_lock/", { user_address: address });
  };

  const purchaseLandHandler = (data) => {
    purchaseLand.writeContract({
      address: process.env.REACT_APP_LAND_CONTRACT_ADDRESS,
      abi: landAbi,
      functionName: "purchaseLand",
      args: [
        data.tilecount,
        data.purchase_price_usd,
        purchasePriceTokenInWei,
        data.token_type,
        data.tile_data,
        data.is_forsale,
      ],
    });
  };

  const approveHandler = async () => {
    approve.writeContractAsync({
      address:
        selectTokenType === "PME"
          ? process.env.REACT_APP_PME_CONTRACT_ADDRESS
          : process.env.REACT_APP_PMB_CONTRACT_ADDRESS,
      abi: selectTokenType === "PME" ? pmeApproveAbi : approveAbi,
      functionName: "approve",
      args: [
        process.env.REACT_APP_LAND_CONTRACT_ADDRESS,
        purchasePriceTokenInWei,
      ],
    });
  };
  const buyLandHandler = async () => {
    try {
      if (
        Number(pmbBalance?.data?.formatted) <
          contractData.purchase_price_token &&
        selectTokenType === "PMB"
      ) {
        toast.error(
          "Your PMB balance is not sufficient to complete the purchase.",
          { toastId: "pmbBalanceError" }
        );
        // closeModalHandler();
      } else if (
        Number(pmeBalance?.data?.formatted) <
          contractData.purchase_price_token &&
        selectTokenType === "PME"
      ) {
        toast.error(
          "Your PME balance is not sufficient to complete the purchase.",
          { toastId: "pmeBalanceError" }
        );
        // closeModalHandler();
      } else if (Number(maticBalance?.data?.formatted) < 0.1) {
        toast.error(
          "Your Matic balance is not sufficient to complete the purchase.",
          { toastId: "maticBalanceError" }
        );
        closeModalHandler();
      } else {
        setIsLoading(true);
        setButtonText("Loading");
        setShowStepper(true);
        await approveHandler();
      }
    } catch (err) {
      console.error("Error in buyLandHandler:", err);
      setIsLoading(false);
      setButtonText("Confirm");
    }
  };

  useEffect(() => {
    const loadingStates = [
      { condition: approve.isPending, text: "Approving" },
      { condition: purchaseLand.isPending, text: "Purchasing The Land" },
      {
        condition: transactionReceipt.isFetching,
        text: "Fetching Approve Data",
      },
      { condition: userBalance.isFetching, text: "Fetching Balance" },
      { condition: isFetchingLand, text: "Fetching Land Data" },
      { condition: isLoading, text: "Loading" },
    ];

    const currentLoadingState = loadingStates.find((state) => state.condition);

    if (currentLoadingState) {
      setButtonText(
        <div>
          {currentLoadingState.text}
          <div className="spinner-border spinner-border-sm ms-2"></div>
        </div>
      );
    } else {
      setButtonText("Confirm");
    }
  }, [
    isLoading,
    approve.isPending,
    purchaseLand.isPending,
    transactionReceipt.isFetching,
    userBalance.isFetching,
    isFetchingLand,
  ]);

  const isDisabled =
    isLoading ||
    purchaseLand.isLoading ||
    approve.isLoading ||
    approve.isPending ||
    purchaseLand.isPending ||
    transactionReceipt.isFetching ||
    userBalance.isFetching ||
    isFetchingLand;
  const stepperList = [
    {
      id: 1,
      title: approve.isPending
        ? "Waiting for Wallet Approval"
        : approve.isError
        ? "Failed to Approve"
        : transactionReceipt.isFetching
        ? "Waiting for Transaction Confirmation"
        : transactionReceipt.isSuccess
        ? "Approval Completed"
        : "Approve",
      status:
        approve.isPending || transactionReceipt.isFetching
          ? "in-progress"
          : transactionReceipt.isSuccess
          ? "active"
          : approve.isError || transactionReceipt.isError
          ? "failed"
          : "in-active",
      retryHandler: approve.isError
        ? approveHandler
        : transactionReceipt.isError
        ? transactionReceipt.refetch
        : null,
    },
    {
      id: 2,
      title: isFetchingLand
        ? "Retrieving Land Information"
        : fetchLandError
        ? "Failed to Retrieve Land Information"
        : fetchLandData
        ? "Retrieved Land Information"
        : "Retrieve Land Information",
      status: isFetchingLand
        ? "in-progress"
        : fetchLandData
        ? "active"
        : fetchLandError
        ? "failed"
        : "in-active",
      retryHandler:
        !fetchLandData && !isFetchingLand && fetchLandError
          ? fetchBuyLandData
          : null,
    },
    {
      id: 3,
      title: purchaseLand.isPending
        ? "Waiting for Wallet Confirmation"
        : purchaseLand.isError
        ? "Failed to Purchase"
        : purchaseLand.isSuccess
        ? "Purchased"
        : "Purchase",
      status: purchaseLand.isPending
        ? "in-progress"
        : purchaseLand.isSuccess
        ? "active"
        : purchaseLand.isError
        ? "failed"
        : "inactive",
      retryHandler: purchaseLand.isError
        ? () => purchaseLandHandler(fetchLandData)
        : null,
    },
  ];
  return (
    <Modal
      isDisabled={isDisabled || !selectTokenType}
      buyLandHandler={buyLandHandler}
      loadingBtnText={buttonText}
      cancelBuyLand={cancelBuyLand}
      closeModalHandler={closeModalHandler}
      tilePrice={titleInfo.tilePrice}
      contractData={contractData}
      purchaseLand={purchaseLand}
      stepperList={stepperList}
      showStepper={showStepper}
      selectTokenType={selectTokenType}
      setSelectTokenType={setSelectTokenType}
    />
  );
};

export default BuyLandModal;
