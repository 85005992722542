import React from "react";

interface LoadingSpinnerProps {
  colSpan: number;
}

const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({ colSpan }) => {
  return (
    <tr className="empty-row">
      <td colSpan={colSpan} className="text-center p-3">
        <div className="text-center">
          <span className="spinner-border spinner-border-md"></span>
        </div>
      </td>
    </tr>
  );
};

export default LoadingSpinner;
