import { useState } from "react";
import Row from "./Row";
import LoadingSpinner from "./LoadingSpinner";
import NoData from "./NoData";
import ClaimModal from "./ClaimModal";

const Table = ({ stakes, isLoading, setRefetch }) => {
  const [openClaimModal, setOpenClaimModal] = useState(false);
  const [stakeDetails, setStakeDetails] = useState(null);

  const toggleClaimModalHandler = () => {
    setOpenClaimModal(!openClaimModal);
  };

  return (
    <>
      <div className="table-responsive mb-5">
        <table className="table table-hover text-nowrap table-bordered">
          <thead>
            <tr>
              <th scope="col">ID</th>
              <th scope="col">Image</th>
              <th scope="col">Type</th>
              <th scope="col">Start Contract</th>
              <th scope="col">Remaining/Duration</th>
              <th scope="col">Claim Status</th>
              <th scope="col">Value</th>
              <th scope="col">Withdrawn</th>
              <th scope="col">Withdrawable</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <LoadingSpinner />
            ) : stakes && stakes.length > 0 ? (
              stakes.map((stake, index) => (
                <Row
                  key={index}
                  stake={stake}
                  setStakeDetails={setStakeDetails}
                  toggleClaimModalHandler={toggleClaimModalHandler}
                />
              ))
            ) : (
              <NoData />
            )}
          </tbody>
        </table>
      </div>
      {openClaimModal && (
        <ClaimModal
          closeModalHandler={toggleClaimModalHandler}
          stakeDetails={stakeDetails}
          setStakeDetails={setStakeDetails}
          setRefetch={setRefetch}
        />
      )}
    </>
  );
};

export default Table;
