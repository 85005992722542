import React from "react";
import { AppSettingsContextType } from "./types";

const defaultContextValue: AppSettingsContextType = {
  theme: "light",
  setTheme: () => {},
};

export const AppSettings =
  React.createContext<AppSettingsContextType>(defaultContextValue);
