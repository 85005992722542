import React from "react";
import { Card, CardBody } from "@/components/Common/Card";
import { copyToClipboard } from "@/utils/copyToClipboard.js";
import { User } from "@/types/user";

interface ProfileFormProps {
  user: User;
  PMBValue: string;
  PMEValue: string;
}

const ProfileForm: React.FC<ProfileFormProps> = ({
  user,
  PMBValue,
  PMEValue,
}) => {
  return (
    <Card className="profile-card">
      <CardBody>
        <div className="profile">
          <div className="profile-container">
            <div className="profile-row">
              <div className="profile-avatar">{user.username.charAt(0)}</div>
              <div>
                <h4>{user.username}</h4>
                <div>ID: {user.user_id}</div>
                <div>Purchased Tiles: {user.num_active_tiles} Tiles</div>
                <div>Total Lands: {user.num_active_lands} Lands</div>
                <div>PME Balance: {PMEValue}</div>
                <div>PMB Balance: {PMBValue}</div>
              </div>
            </div>
          </div>
          <hr className="mt-4 mb-4" />
          <div>
            <h2>User Profile</h2>
            <form className="col">
              <div className="col-xl-3">
                <div className="form-group mb-3">
                  <label className="form-label" htmlFor="username">
                    Username
                  </label>
                  <div className="input-group flex-nowrap">
                    <input
                      type="text"
                      className="form-control"
                      id="username"
                      placeholder="username"
                      defaultValue={user.username}
                      readOnly
                    />
                    <span
                      className="input-group-text"
                      onClick={() => copyToClipboard(user.username)}
                    >
                      <i className="bi me-1 bi-copy"></i>
                    </span>
                  </div>
                </div>
                <div className="form-group mb-3">
                  <label className="form-label" htmlFor="email">
                    Email address
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    placeholder="name@example.com"
                    defaultValue={user.email}
                    readOnly
                  />
                </div>
                <div className="form-group mb-3">
                  <label className="form-label" htmlFor="referrerCode">
                    Referrer Code
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="referrerCode"
                    placeholder="Referrer Code"
                    defaultValue={user.referral_id}
                    readOnly
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default ProfileForm;
