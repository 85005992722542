import React from "react";

interface EmptyPageProps {
  title: string;
}

const EmptyPage: React.FC<EmptyPageProps> = ({ title }) => {
  return (
    <div className="text-center p-3">
      <div className="text-gray-300 mb-2">
        <i className="fas fa-fw me-2 fa-database fa-3x"></i>
      </div>
      {title}
    </div>
  );
};

export default EmptyPage;
