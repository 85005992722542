import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import Menu from "@/config/landing-menu";
import { NavigationProps, MenuItem } from "../types";

const Navigation: React.FC<NavigationProps> = ({
  isAuthenticated,
  menuActive,
  handleMenuActive,
}) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const handleDropdown = (index: number) => {
    setActiveIndex(index);
  };

  return (
    <nav id="main-nav" className={`main-nav ${menuActive ? "active" : ""}`}>
      <ul id="menu-primary-menu" className="menu">
        {isAuthenticated && (
          <li
            onClick={() => handleDropdown(Menu.length)}
            className={`menu-item ${
              activeIndex === Menu.length ? "active" : ""
            }`}
          >
            <Link to="/dashboard/profile" onClick={handleMenuActive}>
              Dashboard
            </Link>
          </li>
        )}
        {Menu.map((data: MenuItem, idx: number) => (
          <li
            key={idx}
            onClick={() => handleDropdown(idx)}
            className={`menu-item ${
              data.namesub ? "menu-item-has-children" : ""
            } ${activeIndex === idx ? "active" : ""}`}
          >
            {data.isDowanloadable ? (
              <a
                href={data.path}
                target="_blank"
                rel="noopener noreferrer"
                download
              >
                {data.title}
              </a>
            ) : (
              <Link to={data.path} onClick={handleMenuActive}>
                {data.title}
              </Link>
            )}

            {data.namesub && (
              <ul className="sub-menu">
                {data.namesub.map((submenu) => (
                  <li key={submenu.id} className="menu-item">
                    <NavLink to={submenu.links}>{submenu.sub}</NavLink>
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Navigation;
