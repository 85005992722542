import React from "react";
import { Link } from "react-router-dom";
import Menu from "@/config/landing-menu";

export const FooterMenu: React.FC = () => (
  <div className="row">
    <div className="col-md-5 me-5">
      <p className="info">
        <span>PCOLand</span> is a platform for selling defined metaverse lands
        all over the world. In <span>PCOLand</span>, you can easily buy and sell
        your land in 3D space and enjoy your metaverse world.
      </p>
    </div>
    <div className="col-md-2">
      <ul className="footer-list">
        {Menu.slice(0, 3).map((item, index) => (
          <li key={index}>
            <Link to={item.path}>{item.title}</Link>
          </li>
        ))}
      </ul>
    </div>
    <div className="col-md-2">
      <ul className="footer-list">
        {Menu.slice(3, 6).map((item, index) => (
          <li key={index}>
            <Link to={item.path}>{item.title}</Link>
          </li>
        ))}
      </ul>
    </div>
    <div className="col-md-2">
      <ul className="footer-list">
        {Menu.slice(6, 7).map((item, index) => (
          <li key={index}>
            <Link to={item.path}>{item.title}</Link>
          </li>
        ))}
      </ul>
    </div>
  </div>
);
