import React, { ReactNode } from "react";
import img from "@/assets/images/post/announcement.png";
interface AnnouncementModalProps {
  closeModalHandler?: () => void;
  children: ReactNode;
  className?: string;
}

const AnnouncementModal: React.FC<AnnouncementModalProps> = ({
  closeModalHandler,
  children,
  className,
}) => {
  return (
    <div className={`announcement-modal ${className ?? ""}`}>
      <div
        className="modal show"
        id="baseModal"
        aria-modal="true"
        role="dialog"
      >
        <div className="modal-dialog h-100 d-flex align-items-center">
          <div className="modal-content">
            <div className="modal-body">
              <div className="d-flex justify-content-center">
                <img src={img} alt="" />
              </div>
              <div>{children}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show"></div>
    </div>
  );
};

export default AnnouncementModal;
