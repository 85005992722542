import { useEffect } from "react";
import { api } from "@/utils/axiosInstance";
import { useAccount } from "wagmi";
const StakeTypeSelector = ({
  selectedStakeType,
  setSelectedStakeType,
  setStakeTypes,
  stakeTypes,
  nftDetails,
  setIsLoading,isBtnDisabled
}) => {
  const { address } = useAccount();

  const convertDaysToYears = (days) => {
    const years = days / 360;
    return years === 1 ? "1 year" : `${years} years`;
  };
  const getLandType = () => {
    setIsLoading(true);
    api
      .post("/stake_types/", {
        token_id: nftDetails.token_id,
        user_address: address,
      })
      .then((res) => {
        setStakeTypes(res.data.eligible_plans);
        setIsLoading(false);
      });
  };
  useEffect(() => {
    getLandType();
  }, [nftDetails.token_id]);
  return (
    <div className="my-3">
      <label className="form-label">Stake Type</label>
      <select
        className="form-select mt-2"
        value={selectedStakeType}
        onChange={(e) => {
          setSelectedStakeType(e.target.value);
        }}
        disabled={isBtnDisabled}
      >
        <option value={""}>Select Your Stake Contract</option>
        {stakeTypes.length ? (
          stakeTypes.map((type, index) => (
            <option value={type.plan_id} key={index}>
              {convertDaysToYears(type.duration_days)}{" "}
              {type.total_profit_percentage}%
            </option>
          ))
        ) : (
          <option value="">Loading...</option>
        )}
      </select>
    </div>
  );
};

export default StakeTypeSelector;
