import React from 'react';

interface NoDataProps {
  colSpan: number;
}

const NoData: React.FC<NoDataProps> = ({ colSpan }) => {
  return (
    <tr className="empty-row">
      <td colSpan={colSpan} className="text-center p-3">
        <div className="text-gray-300 mb-2">
          <i className="fas fa-fw me-2 fa-database fa-3x"></i>
        </div>
        No Data
      </td>
    </tr>
  );
};

export default NoData;
